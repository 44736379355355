import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation, matchPath, useOutletContext } from 'react-router-dom';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import styles from '../styles';

import StorageIcon from '@mui/icons-material/Storage';
import LoggingIcon from 'assets/icons/Logging';
import MyProfileIcon from 'assets/icons/MyProfile';
import SidebarButton from '../SidebarButton';
import DashboardIcon from 'assets/icons/DashboardIcon';
import DocumentationIcon from 'assets/icons/DocumentationIcon';
import OrganizationIcon from 'assets/icons/Organization';
import OrganizationUsersIcon from 'assets/icons/OrganizationUsers';
import ServerIcon from '../../../../assets/icons/ServerIcon';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import StorageAccessModal from 'components/Modals/StorageAccessModal';
import LinkIcon from '@mui/icons-material/Link';

import HttpStatusCodes from 'classes/HttpStatusCodes';
import { isOrganizationStorageEnabled } from 'utils/organizationUtils';

import { openStorageAccessModal } from 'hooks/useModalHook';
import useSelectedOrganization from 'hooks/useSelectedOrganizationHook';

const BlockMenus = ({ collapseMenu }) => {
    const navigate = useNavigate(),
        location = useLocation(),
        [currentSelectedOrganization] = useSelectedOrganization(),
        isUserAdmin = currentSelectedOrganization && currentSelectedOrganization?.isAdmin,
        { applicationAccess = {} } = useOutletContext(),
        { hasConnectAccess } = applicationAccess;

    const organizationItems = [
        {
            route: '/organization',
            label: 'Information',
            icon: <OrganizationIcon fill="#fff" style={styles.orgSettingMenuIcon} />,
            isVisible: isUserAdmin,
        },
        {
            route: '/organization/organization-users',
            label: 'Users',
            icon: <OrganizationUsersIcon fill="#fff" style={styles.orgUserMenuIcon} />,
            isVisible: isUserAdmin,
        },
    ];

    if (hasConnectAccess) {
        organizationItems.push({
            route: '/organization/logging',
            label: 'Logging',
            icon: <LoggingIcon fill="#fff" style={styles.orgUserMenuIcon} />,
            isVisible: isUserAdmin,
        });
        organizationItems.push({
            route: '/organization/vpn-management-access',
            label: 'VPN Management',
            icon: <ServerIcon fill="#fff" style={styles.orgVpnMenuIcon} />,
            isVisible: isUserAdmin,
        });
        organizationItems.push({
            key: '/organization/file-storage',
            label: 'My Files',
            icon: <StorageIcon htmlColor="#fff" style={styles.orgVpnMenuIcon} />,
            isVisible: isUserAdmin,
            onClick: async () => {
                const organizationId = currentSelectedOrganization?.organizationId;

                if (organizationId) {
                    try {
                        const isStorageEnabled = await isOrganizationStorageEnabled(organizationId);

                        if (isStorageEnabled) {
                            navigate('/organization/file-storage');
                        }
                    } catch (e) {
                        if (e.response.status === HttpStatusCodes.NOT_FOUND) {
                            openStorageAccessModal(e.response.data.message.requestedAt);
                        }
                    }
                }
            },
        });
        organizationItems.push({
            url: `${process.env.REACT_APP_INTELY_CONNECT_EXTERNAL_HOST}/app-instances/new?sandbox=true`,
            label: 'Setup Sandbox',
            icon: <AutoAwesomeIcon style={{ ...styles.orgVpnMenuIcon, fill: '#FFF' }} />,
            isVisible: isUserAdmin,
        });

        if (process.env.REACT_APP_CURRENT_ENVIRONMENT === 'local') {
            organizationItems.push({
                url: `${process.env.REACT_APP_INTELY_UI_EXTERNAL_HOST}/test-landing`,
                label: 'Transfer to UI',
                icon: (
                    <LinkIcon
                        style={{
                            ...styles.orgVpnMenuIcon,
                            fill: '#FFF',
                        }}
                    />
                ),
                isVisible: isUserAdmin,
            });
        }
    }

    const blockMenus = [
        {
            title: 'Menu',
            items: [
                {
                    route: '/dashboard',
                    label: 'Dashboard',
                    icon: <DashboardIcon fill="#fff" style={styles.menuIcon} />,
                },
                {
                    url: `${process.env.REACT_APP_INTELY_UI_EXTERNAL_HOST}/my-profile`,
                    label: 'My Profile',
                    icon: <MyProfileIcon fill="#fff" style={styles.profileIcon} />,
                },
                {
                    label: 'Documentation',
                    icon: <DocumentationIcon fill="#fff" style={styles.menuIcon} />,
                    url: 'https://documentation.intely.io/',
                    target: '_blank',
                },
            ],
        },
        {
            title: 'Organization',
            isVisible: isUserAdmin,
            items: organizationItems,
        },
    ];

    /**
     * Handle route/url
     * @param {{ route: string, url: string, target?: string, onClick: function}} item
     * @returns {*}
     */
    const handleRoute = (item) => () => {
        if (item.route) {
            navigate(item.route);
        } else if (item.url) {
            if (item.target) {
                window.open(item.url, item.target);
            } else {
                window.location.assign(item.url);
            }
        } else if (item.onClick) {
            item.onClick();
        }
    };

    const wrapperStyle = {
        ...styles.wrapper,
    };

    if (collapseMenu) {
        wrapperStyle.padding = '20px 10px';
    }

    return blockMenus.map((blockMenu) => {
        if (blockMenu?.isVisible === false) return null;

        return (
            <Fragment key={blockMenu.title}>
                <Divider style={styles.divider} />

                <div style={wrapperStyle}>
                    {!collapseMenu && (
                        <Typography component="h2" sx={styles.title}>
                            {blockMenu.title}
                        </Typography>
                    )}

                    {blockMenu?.items?.map((item) => {
                        if (item?.isVisible === false) return null;

                        const pathPattern = {
                            end: true,
                            path: item.route || item.url || item.key,
                        };

                        return (
                            <SidebarButton
                                key={pathPattern.path}
                                label={item.label}
                                icon={item.icon}
                                onClick={handleRoute(item)}
                                isActive={matchPath(pathPattern, location.pathname) !== null}
                                collapseMenu={collapseMenu}
                            />
                        );
                    })}
                </div>

                <StorageAccessModal />
            </Fragment>
        );
    });
};

BlockMenus.propTypes = {
    collapseMenu: PropTypes.bool.isRequired,
};

export default BlockMenus;
