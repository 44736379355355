import * as constants from 'config/constants';
import { Cookies } from 'react-cookie';
const cookies = new Cookies();

/**
 * Set cookie. Cookie is always secured.
 * @param {{ key: String, value: String, path: String, domain: String, expires: String, sameSite: String }} args.
 * @param {String} args.key. Cookie key
 * @param {String} args.value. Cookie value
 * @param {String} args.path. Cookie path. Default: '/'
 * @param {String} args.domain. Cookie domain. Default to env domain (constants.CURRENT_DOMAIN_SUFFIX).
 * @param {String} args.expires. Parsable date by Date(). Example: 'Jan 31, 2000'
 * @param {String} args.sameSite. Default: 'Lax'
 */
export const setCookie = ({
    key,
    value,
    path = '/',
    expires = '',
    domain = constants.CURRENT_DOMAIN_SUFFIX,
    sameSite = 'Lax',
}) => {
    if (document) {
        const date = new Date(expires);
        if (!date?.getDate || isNaN(date?.getDate())) return;

        const expiration = date.toUTCString();

        document.cookie = `${key}=${value}; path=${path}; domain=${domain}; expires=${expiration}; SameSite=${sameSite}; Secure";`;
    }
};

/**
 * Get cookie
 * @param {String} key. Cookie key
 * @return {String} Cookie value
 */
export const getCookie = (key) => {
    if (document) {
        return document.cookie
            ?.split('; ')
            ?.find((row) => row.startsWith(`${key}=`))
            ?.split('=')[1];
    }
    return null;
};

/**
 * @param organizationId
 */
export const setOrganizationCookie = (organizationId) => {
    cookies.set('currentOrganizationId', organizationId, {
        secure: true,
        domain: '.' + constants.CURRENT_DOMAIN_SUFFIX,
        sameSite: 'strict',
        path: '/',
    });
};

/**
 * @returns {any}
 */
export const getOrganizationFromCookie = () => {
    return cookies.get('currentOrganizationId');
};
