import { useEffect } from 'react';
import { logout } from '../../utils/loginUtils';
import { useSearchParams } from 'react-router-dom';
import { clarity } from 'react-microsoft-clarity';

const Logout = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (typeof window !== 'undefined') {
            // Stop Clarity tracking
            if (clarity.hasStarted()) {
                clarity.stop();
            }

            logout('', true, searchParams.has('forbidden'));
        }
    }, []);

    return <></>;
};

export default Logout;
