import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useOutletContext } from 'react-router-dom';

import styles from '../styles';
import SidebarButton from '../SidebarButton';
import MapperIcon from 'assets/icons/MapperIcon';
import ConnectIcon from 'assets/icons/ConnectIcon';
import FormIcon from 'assets/icons/FormIcon';
import useSelectedOrganization from 'hooks/useSelectedOrganizationHook';

const ApplicationsMenu = ({ sidebarMenu }) => {
    const [currentSelectedOrganization] = useSelectedOrganization(),
        { applicationAccess = {} } = useOutletContext(),
        { hasFormsAccess, hasConnectAccess } = applicationAccess;

    const handleNavigation = (route) => () => {
        window.location.replace(route + '?currentOrganizationId=' + currentSelectedOrganization?.organizationId || '');
    };

    return (
        <div style={{ backgroundColor: '#4F4F4F' }}>
            <Divider style={styles.applicationMenuDivider} />
            <div style={{ padding: sidebarMenu.collapseMenu ? '20px 10px' : '20px' }}>
                {hasConnectAccess || hasFormsAccess ? (
                    !sidebarMenu.collapseMenu && (
                        <Typography component="h2" sx={styles.title}>
                            Applications
                        </Typography>
                    )
                ) : (
                    <div style={{ color: 'white', textAlign: 'center' }}>No Subscriptions Active</div>
                )}

                {hasConnectAccess ? (
                    <>
                        <SidebarButton
                            label="Connect"
                            onClick={handleNavigation(`${process.env.REACT_APP_INTELY_UI_EXTERNAL_HOST}/integrations`)}
                            icon={<ConnectIcon style={styles.menuIcon} />}
                            collapseMenu={sidebarMenu.collapseMenu}
                        />

                        <SidebarButton
                            label="Mapper"
                            onClick={handleNavigation(process.env.REACT_APP_INTELY_CONNECT_EXTERNAL_HOST + '/rosetta')}
                            icon={<MapperIcon style={styles.menuIcon} />}
                            collapseMenu={sidebarMenu.collapseMenu}
                        />
                    </>
                ) : null}

                {hasFormsAccess ? (
                    <SidebarButton
                        label="Forms"
                        onClick={handleNavigation(process.env.REACT_APP_INTELY_FORMS_EXTERNAL_HOST)}
                        icon={<FormIcon style={styles.menuIcon} />}
                        collapseMenu={sidebarMenu.collapseMenu}
                    />
                ) : null}
            </div>
        </div>
    );
};

ApplicationsMenu.propTypes = {
    sidebarMenu: PropTypes.object.isRequired,
};

export default ApplicationsMenu;
