/* eslint-disable */
import { useEffect } from 'react';

import useSelectedOrganization from './useSelectedOrganizationHook';
import { getAuthUserData } from './useUserHook';

import * as constants from '../config/constants';

/**
 * Delighted NPS Survey
 */
const useDelightedSurvey = () => {
    const [selectedOrganization] = useSelectedOrganization();

    useEffect(() => {
        async function setupDelightedSurvey() {
            if (!constants.IS_PRODUCTION || !selectedOrganization?.organizationId) {
                return;
            }

            const user = getAuthUserData();
            if (!user?.email) return;

            !(function (e, t, r, n) {
                if (!e[n]) {
                    for (
                        var a = (e[n] = []),
                            i = [
                                'survey',
                                'reset',
                                'config',
                                'init',
                                'set',
                                'get',
                                'event',
                                'identify',
                                'track',
                                'page',
                                'screen',
                                'group',
                                'alias',
                            ],
                            s = 0;
                        s < i.length;
                        s++
                    ) {
                        var c = i[s];
                        a[c] =
                            a[c] ||
                            (function (e) {
                                return function () {
                                    var t = Array.prototype.slice.call(arguments);
                                    a.push([e, t]);
                                };
                            })(c);
                    }
                    a.SNIPPET_VERSION = '1.0.1';
                    var o = t.createElement('script');
                    o.type = 'text/javascript';
                    o.async = true;
                    o.nonce = window.nonce;
                    o.src = '/scripts/delighted.js';
                    var p = t.getElementsByTagName('script')[0];
                    p.parentNode.insertBefore(o, p);
                }
            })(window, document, 'kqhZhvtsrjaJVQzp', 'delighted');

            delighted.survey({
                email: user.email,
                name: `${user.firstName} ${user.lastName}`,
                properties: {
                    organizationName: selectedOrganization?.organizationName,
                },
            });
        }

        setupDelightedSurvey();
    }, [selectedOrganization?.organizationId]);
};

export default useDelightedSurvey;
