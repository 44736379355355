import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';

import { AUTH_FORM_TABS } from '../config/constants';

import Logout from '../components/Logout';
import AppLoader from '../components/Loaders/AppLoader';
import AppRoutesContainer from '../components/Containers/AppRoutesContainer';
import RenderDashboardPage from 'components/RenderDashboardPage';

const asyncLoaderFallback = { fallback: <AppLoader /> };

const Auth = loadable(() => import('../components/Auth'), asyncLoaderFallback);

const Organization = loadable(() => import('../components/Settings/Organization'), asyncLoaderFallback);

const AuthRoutesContainer = loadable(() => import('../components/Containers/AuthRoutesContainer'), asyncLoaderFallback);

const DashboardContainer = loadable(() => import('../components/Containers/DashboardContainer'), asyncLoaderFallback);

const ErrorPage = loadable(() => import('../components/ErrorPage'));

const AppRoutes = ({ isUserLoggedIn, applicationAccessRef }) => (
    <Router>
        <Routes>
            <Route element={<AppRoutesContainer isUserLoggedIn={isUserLoggedIn} />}>
                {/* Public Routes (Authentication) */}
                <Route element={<AuthRoutesContainer isUserLoggedIn={isUserLoggedIn} />}>
                    <Route exact path="/" element={<Auth initialTab={AUTH_FORM_TABS.login} />} />

                    <Route exact path="/login" element={<Auth initialTab={AUTH_FORM_TABS.login} />} />

                    <Route exact path="register" element={<Auth initialTab={AUTH_FORM_TABS.register} />} />

                    <Route exact path="accept-invite/:invite" element={<Auth initialTab={AUTH_FORM_TABS.register} />} />

                    <Route exact path="resetpass" element={<Outlet />}>
                        <Route index element={<Auth initialTab={AUTH_FORM_TABS.resetPassword} />} />

                        <Route
                            exact
                            path=":id/:token"
                            element={<Auth initialTab={AUTH_FORM_TABS['resetPassword:id:token']} />}
                        />
                    </Route>

                    <Route
                        exact
                        path="resend-verify-code"
                        element={<Auth initialTab={AUTH_FORM_TABS.resendVerificationCode} />}
                    />
                </Route>

                {/* Protected Routes (Dashboard) */}
                <Route
                    element={
                        <DashboardContainer
                            isUserLoggedIn={isUserLoggedIn}
                            applicationAccessRef={applicationAccessRef}
                        />
                    }
                >
                    <Route element={<RenderDashboardPage isUserLoggedIn={isUserLoggedIn} />}>
                        <Route index exact path="dashboard" element={<></>} />
                        <Route element={<Organization isUserLoggedIn={isUserLoggedIn} />}>
                            <Route exact path="organization/organization-users" element={<></>} />
                            <Route exact path="organization/logging" element={<></>} />
                            <Route exact path="organization/vpn-management-access" element={<></>} />
                            <Route exact path="organization/file-storage" element={<></>} />
                            <Route index exact path="organization" element={<></>} />
                        </Route>
                    </Route>
                </Route>

                {/* Route Not Found */}
                <Route path="*" element={<ErrorPage errorCode={404} />} />
            </Route>

            <Route exact path="/logout" element={<Logout />} />
        </Routes>
    </Router>
);

AppRoutes.propTypes = {
    isUserLoggedIn: PropTypes.bool.isRequired,
    applicationAccessRef: PropTypes.object.isRequired,
};

export default AppRoutes;
