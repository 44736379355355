import { memo } from 'react';
import loadable from '@loadable/component';
import { clarity } from 'react-microsoft-clarity';

import AppContainer from './components/Containers/AppContainer';
import PlatformErrorBoundary from 'components/PlatformErrorBoundary';
import { IS_PRODUCTION } from './config/constants';

if (IS_PRODUCTION) {
    clarity.init('q67urxl7tc');
    clarity.setTag('env', process.env.REACT_APP_CURRENT_ENVIRONMENT);
    clarity.setTag('app', 'intelyPlatform');
}

const Snackbar = loadable(() => import('./components/Snackbar'));

const ConfirmationModal = loadable(() => import('./components/Modals/ConfirmationModal'));

const App = () => (
    <PlatformErrorBoundary>
        <Snackbar />
        <ConfirmationModal />
        <AppContainer />
    </PlatformErrorBoundary>
);

export default memo(App);
