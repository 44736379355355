import { useState, useRef } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import '../../../App.css';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

import Routes from '../../../route';
import AppLoader from '../../Loaders/AppLoader';

import {
    logout,
    getCurrentUserTokenData,
    updateCurrentUserData,
    restoreUserSessionStates,
    updateUserLastRefreshTime,
    updateUserLastActivityTime,
    getUserOrganizationList,
    hasUserLoggedIn,
    getCurrentOrganizationId,
} from '../../../utils/loginUtils';
import { getOrganizationUsersData } from 'utils/organizationUtils';

import useSelectedOrganization from '../../../hooks/useSelectedOrganizationHook';
import { setOrganizationUsersData } from 'hooks/useOrganizationUsersHook';
import { showSnackBarWarningNotification } from '../../../utils/snackBarNotificationUtils';
import { setOrganizationCookie } from '../../../utils/cookiesUtils';

const AppContainer = () => {
    const [showAppLoadingComponent, setShowAppLoadingComponent] = useState(true),
        isUserLoggedIn = hasUserLoggedIn(),
        applicationAccessRef = useRef();

    const [, setCurrentSelectedOrganization] = useSelectedOrganization();

    /**
     * Set current organization before route navigate
     * @param {string} userId current login userId
     * @returns {Promise<string>} current organizationId
     */
    const getUserOrganizations = async (userId) => {
        return await getUserOrganizationList(userId)
            .then((data) => {
                const currentOrganizationId = getCurrentOrganizationId();

                if (data && !currentOrganizationId) {
                    const organizationId = data[0]?.organizationId || '';
                    localStorage.setItem('currentOrganizationId', organizationId);
                    setOrganizationCookie(organizationId);
                    setCurrentSelectedOrganization(data[0]);
                    return organizationId;
                } else {
                    const currentOrganization = data.find(
                        (organization) => organization.organizationId === currentOrganizationId,
                    );

                    if (currentOrganization) {
                        setCurrentSelectedOrganization(currentOrganization);
                        return currentOrganizationId;
                    }
                }
            })
            .catch(() => {
                logout('session.application.error', true);
            });
    };

    /**
     * Get organization users and set to redux store
     * @param {string} organizationId
     */
    const getOrganizationUsers = async (organizationId) => {
        await getOrganizationUsersData(organizationId)
            .then((data) => {
                setOrganizationUsersData(data);
            })
            .catch(() => {
                logout('session.application.error', true);
            });
    };

    /**
     * @param {*} data
     */
    const init = async (data) => {
        if (data.userId) {
            const organizationId = await getUserOrganizations(data.userId);
            await getOrganizationUsers(organizationId);
        }

        // notify users of pending password expiration
        const numberOfDaysUntilPasswordExpire = data.passwordSetAt
            ? 90 - dayjs().diff(dayjs(data.passwordSetAt), 'days')
            : 0;
        if (numberOfDaysUntilPasswordExpire <= 7) {
            let message;
            if (numberOfDaysUntilPasswordExpire > 0) {
                message = `Your password will expire in ${numberOfDaysUntilPasswordExpire} days.`;
            } else if (numberOfDaysUntilPasswordExpire === 0) {
                message = `Your password will expire in today.`;
            } else {
                message = `Your password is expired.`;
            }
            message += ' Please update your password within the user profile section of our platform.';

            showSnackBarWarningNotification(message);
        }

        await updateCurrentUserData(data);

        updateUserLastRefreshTime();
        updateUserLastActivityTime();
        restoreUserSessionStates();
    };

    window.onload = async function () {
        // if the user has a current login, validate it and then update some store user values
        if (hasUserLoggedIn()) {
            await getCurrentUserTokenData()
                .then(async (data) => {
                    if (data) {
                        try {
                            await init(data);
                        } catch (e) {
                            logout('session.application.error', true);
                        }
                    } else {
                        logout('session.application.error', true);
                    }
                })
                .catch(() => {
                    logout('session.invalid', true);
                })
                .finally(() => {
                    setShowAppLoadingComponent(false);
                });
        } else {
            setShowAppLoadingComponent(false);
        }
    };

    return showAppLoadingComponent ? (
        <AppLoader />
    ) : (
        <Routes isUserLoggedIn={isUserLoggedIn} applicationAccessRef={applicationAccessRef} />
    );
};

export default AppContainer;
